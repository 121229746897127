.site-footer {
	@include font-size(8, mobile, true, true, true);
	@if (get-font-size(8, desktop) != get-font-size(8, mobile)) {
		@include media(">medium") {
			@include font-size(8, desktop, true, true, true);
		}
	}
	background: color(bg, 1i);
	color: color(typography, 2);

	a {
		@include anchor-aspect(footer);
	}

	&::before {
		display: none;

		.lights-off & {
			display: block;
		}
	}
}

.site-footer-inner {
	position: relative; /* To display all elements above the background color */
	display: flex;
	flex-wrap: wrap;
	padding-top: 48px;
	padding-bottom: 48px;
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
	flex: none;
	width: 100%;
	justify-content: center;
}

.footer-brand,
.footer-links,
.footer-social-links {
	margin-bottom: 24px;
}

.footer-social-links {
	li {
		display: inline-flex;

		a {
			padding: 8px;
		}
	}
}

@include media(">medium") {
	.site-footer-inner {
		justify-content: space-between;
		padding-top: 72px;
		padding-bottom: 72px;
	}

	.footer-brand,
	.footer-links,
	.footer-social-links,
	.footer-copyright {
		flex: 50%;
	}

	.footer-brand,
	.footer-copyright {
		justify-content: flex-start;
	}

	.footer-links,
	.footer-social-links {
		justify-content: flex-end;
	}

	.footer-links {
		order: 1;
		margin-bottom: 0;
	}
}
