.cta {
	position: relative;

	.section-inner {
		padding-bottom: 64px;
	}

	&::before {
		content: "";
		position: absolute;
		bottom: -32px;
		left: calc(50% - 720px);
		height: 263px;
		width: 1440px;
		background-image: url("../images/cta-illustration-light.svg");

		.lights-off & {
			background-image: url("../images/cta-illustration-dark.svg");
		}
	}
}

.cta-cta {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-around;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.cta-demo-item {
	background-color: white;
	height: 128px;
	width: 128px;
	margin-bottom: 20px;
	border: 1px solid gray;
	padding: 16px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.formkit-form {
	margin: 0 auto;
	h1 {
		margin-top: unset;
		margin-bottom: unset;
	}
}

.formkit-image {
	padding: 24px;

	img {
		height: 100px;
	}
}

.cta-demo-item:hover {
	border: 3px solid gray;
	transition: border 0.15s ease-in-out;
}

@include media("<medium") {
	.cta-cta {
		.button {
			display: flex;
		}
	}
}

@include media(">medium") {
	.cta {
		.section-inner {
			padding-bottom: 128px;
		}

		.section-paragraph {
			margin-bottom: 40px;
			padding-left: 72px;
			padding-right: 72px;
		}

		&::before {
			bottom: 0;
		}
	}

	.cta-cta {
		.button {
			min-width: 170px;
		}
	}
}
